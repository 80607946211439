import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { LandingService } from '../../_services/landing.service';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'app-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss']
})
export class GridComponent implements OnInit {
  cid:any;
  catItem: any;
  countrylist: any;
  selectedCountry: any;
  fileUrl;
  display='none'; //default Variable
  vdoUrl;
  safeURL;
  showLoader: boolean;

  productsCidData: any;
  productFilter: Array<any>;
  productList: Array<any>;

  constructor( private route: ActivatedRoute, private router: Router, private sanitizer: DomSanitizer, private landingService: LandingService,) { 
    this.route.params.forEach((urlParams) => {
      this.cid = urlParams['cid'];
    });
    this.showLoader = true;
  }

  ngOnInit() {
    this.getCategory();
    this.getCountry();
    this.getProductlist();

    // const data = 'some text';
    // const blob = new Blob([data], { type: 'application/octet-stream' });

    // this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));
  }


  getCategory() {
    this.landingService.getCategory(this.cid).subscribe(
      res => {
        console.log(res);
        this.catItem = res;
      },
      err => {
        console.log(err);
      }
    );
  }

  getCountry(){
    this.landingService.getCountrylist().subscribe(
      res => {
        this.countrylist = res;
        //console.log(res);
      },
      err => { 
        console.log(err);
      }
    );
  }

  listClick(event, newValue) {
    this.selectedCountry = newValue;  // don't forget to update the model here
    if(this.selectedCountry === undefined){
      this.selectedCountry = "All";
    }else{
      this.selectedCountry = newValue;
    }
    this.getProductlist(this.selectedCountry);
  }

  getProductlist(selCountry?){
    this.productFilter = [];
    this.landingService.getProductsbyCategory(this.cid).subscribe(
      res => {
        this.productsCidData = res;
        console.log(this.productsCidData);
        this.productsCidData.forEach(el =>{
          let _id = el['_id'];
          let _title = el['title'];
          let _description = el['description'];
          let _country= el['country'];
          let _type= el['type'];
          let _subcategory= el['subcategory'];
          //let _places= el['places'];
          //let _duration = el['duration'];
          let _thumb = el['thumb'];
          let _attachment= el['attachment'];
          let _attachmentview= el['attachmentview'];
          let _url= el['url'];

          this.productFilter.push({'id':_id,'title':_title, 'subcategory': _subcategory, 'description':_description, 'type': _type,'country': _country, 'thumb': _thumb, 'attachment': _attachment, 'attachmentview': _attachmentview, 'url': _url});
     
        })

        console.log(this.productFilter);
        console.log(selCountry);
        this.productList = [];
        this.productFilter.forEach(data =>{
          if((selCountry === undefined) || (selCountry['name'] === "All")){
            this.productList.push(data);
          }else{
            if (data.country.indexOf(selCountry['name']) > -1) {
              this.productList.push(data);
            }
          }
        })
      
      this.showLoader = false;
      },
      err => { 
        console.log(err);
      }
    );
  }

  openModalDialog(url){
    this.display='block'; //Set block css
    this.vdoUrl = url;
    this.safeURL = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/'+ this.vdoUrl);
  }

  closeModalDialog(){
    this.display='none'; //set none css after close dialog
    this.safeURL = this.sanitizer.bypassSecurityTrustResourceUrl('');
  }
  

  gridInfoview(){
    this.router.navigate(['/user/gridinfo', {cid: this.cid}]);
  }
}
