import { Component, OnInit } from '@angular/core';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation } from 'ngx-gallery';
@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
  // galleryOptions: NgxGalleryOptions[];
  // galleryImages: NgxGalleryImage[];
  constructor() { }

  ngOnInit() {

  //   this.galleryOptions = [
  //     { "imageAutoPlay": true, "imageAutoPlayPauseOnHover": true, },
  //     { "image": false, "height": "100px" },
  //     { "breakpoint": 500, "width": "100%" }
  // ];

  // this.galleryImages = [
  //     {
  //         small: 'assets/img/taai.jpg',
  //         medium: 'assets/img/taai.jpg',
  //         //big: 'assets/img/taai.jpg'
  //     },
  //     {
  //         small: 'assets/img/site.jpg',
  //         medium: 'assets/img/site.jpg',
  //         //big: 'assets/img/site.jpg'
  //     },
  //     {
  //         small: 'assets/img/atoai.jpg',
  //         medium: 'assets/img/atoai.jpg',
  //         //big: 'assets/img/atoai.jpg'
  //     },
  //     {
  //         small: 'assets/img/site.jpg',
  //         medium: 'assets/img/site.jpg',
  //         //big: 'assets/img/site.jpg'
  //     },
  //     {
  //         small: 'assets/img/atoai.jpg',
  //         medium: 'assets/img/atoai.jpg',
  //         //big: 'assets/img/atoai.jpg'
  //     }
      
  // ];

   }

}
