import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../_services/user.service';
@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.css']
})
export class ForgotComponent implements OnInit {
  forgotForm: FormGroup;
  submitted = false;
  
  showSucessMessage: boolean;
  serverErrorMessages: string;
  showWarningMessage:boolean;
  message: string;
  showLoader: boolean;

  constructor(private formBuilder: FormBuilder,  private userService:UserService,) {
    this.showLoader = false;
   }

  ngOnInit() {
    this.forgotForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  get f() { return this.forgotForm.controls; }

  submit(){
    this.submitted = true;

    if(this.forgotForm.valid){
      this.showLoader = true;
      this.userService.forgotpassword(this.forgotForm.value).subscribe(
        res => {
          console.log(res);
          this.message = res['message'];
          if(this.message === "Please check your mail for password reset link"){
            this.showSucessMessage = true;
            setTimeout(() => this.showSucessMessage = false, 4000);
            this.showLoader = false;
          }else{
            this.showWarningMessage = true;
            this.showLoader = false;
            setTimeout(() => this.showWarningMessage = false, 4000);
          }
        },
        err => {
          this.showLoader = false;
          this.serverErrorMessages = err.error.message;
        }
      );
    }
    
  }
}
