import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { LandingService } from '../../_services/landing.service';
import { TagsService } from '../../_services/tags.service';
import { DomSanitizer } from '@angular/platform-browser';
import { DatePipe } from '@angular/common'
@Component({
  selector: 'app-itenary',
  templateUrl: './itenary.component.html',
  styleUrls: ['./itenary.component.scss']
})
export class ItenaryComponent implements OnInit {
  cid:any;
  catItem: any;
  countrylist: any;
  selectedCountry: any;
  selectedTag:any;
  fileUrl;
  taglist;
  selCountry;
  selTag;
  showLoader: boolean;
  productsCidData: any;

  public months = [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ];;

  productFilter: Array<any>;
  productList: Array<any>;

  constructor( private route: ActivatedRoute, private router: Router, public datepipe: DatePipe,  private tagsService:TagsService, private sanitizer: DomSanitizer, private landingService: LandingService,) { 
    this.route.params.forEach((urlParams) => {
      this.cid = urlParams['cid'];
    });
    this.showLoader = true;
  }

  ngOnInit() {
    this.getCategory();
    this.getCountry();
    this.getProductlist();
    this.getTags();
    const data = '';
    const blob = new Blob([data], { type: 'application/octet-stream' });
    this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));
  }

  getCategory() {
    this.landingService.getCategory(this.cid).subscribe(
      res => {
        this.catItem = res;
      },
      err => {
        console.log(err);
      }
    );
  }

  getTags(){
    this.tagsService.getTaglist().subscribe(
      res => {
        this.taglist = res;
      }
    )
  }

  getCountry(){
    this.landingService.getCountrylist().subscribe(
      res => {
        this.countrylist = res;
      },
      err => { 
        console.log(err);
      }
    );
  }

  listClick(event, newValue) {
    this.selectedCountry = newValue;  // don't forget to update the model here
    if(this.selectedCountry === undefined && this.selectedTag === undefined){
      this.selectedCountry = "All";
      this.selectedTag = "All";
      this.getProductlist(this.selectedCountry, this.selectedTag);
    }else if(this.selectedCountry !== undefined && this.selectedTag === undefined){
      this.selectedCountry = newValue;
      this.selectedTag = "All";
      this.getProductlist(this.selectedCountry['name'], this.selectedTag);
    }else{
      this.selectedCountry = newValue;
      this.getProductlist(this.selectedCountry['name'], this.selectedTag['name']);
    }
  }

  tagClick(event, newValue) {
    this.selectedTag = newValue;  // don't forget to update the model here
    if(this.selectedCountry === undefined && this.selectedTag === undefined){
      this.selectedCountry = "All";
      this.selectedTag = "All";
      this.getProductlist(this.selectedCountry, this.selectedTag);
    }else if(this.selectedCountry === undefined && this.selectedTag !== undefined){
      this.selectedTag = newValue;
      this.selectedCountry = "All";
      this.getProductlist(this.selectedCountry, this.selectedTag['name']);
    }else{
      this.selectedTag = newValue;
      this.getProductlist(this.selectedCountry['name'], this.selectedTag['name']);
    }
  }

  getProductlist(selCountry?, selTag?){
    this.productFilter = [];
    this.landingService.getProductsbyCategory(this.cid).subscribe(
      res => {
        this.productsCidData = res;
        this.productsCidData.forEach(el =>{
          let _title = el['title'];
          let _description = el['description'];
          let _country= el['country'];
          let _tags= el['tags'];
          let _places= el['places'];
          let _duration = el['duration'];
          let _edate = el['eventDate']
          let _eventDate = this.datepipe.transform(_edate, 'd MMMM y');
          let _thumb = el['thumb'];
          let _attachment= el['attachment'];
          let _attachmentview= el['attachmentview'];
          let _url= el['url'];
          this.productFilter.push({'title':_title, 'description':_description, 'tags': _tags, 'country': _country, 'duration': _duration, 'eventDate': _eventDate, 'thumb': _thumb, 'attachment': _attachment, 'attachmentview':_attachmentview, 'places': _places, 'url': _url});
        })

          this.productList = [];
          this.productFilter.forEach(data =>{
            if((selTag === undefined && selCountry === undefined) || (selTag === "All" && selCountry === "All") ){
              this.productList.push(data);
              this.showLoader = false;
            }else if(selCountry !== "All" && selTag === undefined){
              if (data.country.indexOf(selCountry) > -1) {
                this.productList.push(data);
              }
            }else if(selCountry === "All" && selTag !== undefined){
              if ((data.tags !==null && data.tags.indexOf(selTag) > -1)) {
                this.productList.push(data);
              }
            }else if(selCountry === "All" && selTag === undefined){
              this.productList.push(data);
            }else if((selCountry !== "All" && selCountry !== undefined) && (selTag !== "All" && selTag !== undefined)){
              if ((data.tags !==null && data.tags.indexOf(selTag) > -1) && (data.country.indexOf(selCountry) > -1)) {
                this.productList.push(data);
              }
            }else{
              this.productList.push(data);
              this.showLoader = false;
            }
          })
        
        
      },
      err => { 
        console.log(err);
      }
    );
  }
  
}
