import { Routes } from '@angular/router';
import { UserComponent } from './user/user.component';
import { SignUpComponent } from './user/sign-up/sign-up.component';
import { SignInComponent } from './user/sign-in/sign-in.component';
import { LandingComponent } from './user/landing/landing.component';
import { ForgotComponent } from './user/forgot/forgot.component';
import { ResetComponent } from './user/reset/reset.component';
import { GoldSignupComponent } from './user/gold-signup/gold-signup.component';
import { AboutComponent } from './user/about/about.component';
import { ItenaryComponent } from './user/itenary/itenary.component';
import { GridComponent } from './user/grid/grid.component';
import { HomeComponent } from './user/home/home.component';
import { GridinfoComponent } from './user/grid/gridinfo/gridinfo.component';
import { ExperiencesComponent } from './user/experiences/experiences.component';
import { FestivalsComponent } from './user/festivals/festivals.component';
import { ImageLibraryComponent } from './user/image-library/image-library.component';
import { AuthGuard } from './auth/auth.guard';
import { from } from 'rxjs';

export const appRoutes: Routes = [
    {
        path: 'login', component: UserComponent,
        children: [{ path: '', component: SignInComponent }]
    },
    {
        path: 'user', component: UserComponent,
        canActivate:[AuthGuard],
        children: [
            { path: '', component: LandingComponent },
            { path: 'landing', component: LandingComponent },
            { path: 'about', component: AboutComponent },
            { path: 'list', component: ItenaryComponent },
            { path: 'experiences', component: ExperiencesComponent },
            { path: 'festivals', component: FestivalsComponent },
            { path: 'grid', component: GridComponent },
            { path: 'gridinfo', component: GridinfoComponent },

            
            { path: 'images', component: ImageLibraryComponent },
            // { path: 'edit', component: EditUserComponent },
            // { path: 'addCategory', component: CategoryComponent },
            // { path: 'categorypost', component: CategoryPostsComponent },
            // { path: 'addpost', component: AddPostComponent },
            // { path: 'country', component: CountrylistComponent },
            // { path: 'adduser', component: AdduserComponent }
        ]
    },
    {
        path: 'login', component: UserComponent,
        children: [{ path: '', component: SignInComponent }]
    },
    {
        path: 'register', component: UserComponent,
        children: [{ path: '', component: SignUpComponent }]
    },
    {
        path: 'goldsignup', component: UserComponent,
        children: [{ path: '', component: GoldSignupComponent }]
    },
    {
        path: 'forgot', component: UserComponent,
        children: [{ path: '', component: ForgotComponent }]
    },
    {
        path: 'reset', component: UserComponent,
        children: [{ path: '', component: ResetComponent }]
    },
    {
        path: 'home', component: UserComponent,
        children: [{ path: '', component: HomeComponent }]
    },
    {
        path: '', redirectTo: '/home', pathMatch: 'full'
    }
  ];