import { Injectable } from '@angular/core';
import { Category } from '../_models/category.model';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
export interface Category {
  order: number;
  slug: string;
  prename?: string;
  name: string;
  postname?:string;
  file: string;
  description: string;
  type: string;
  link?: string
}

@Injectable({
  providedIn: 'root'
})
export class LandingService {

  constructor(private http: HttpClient) { }

  getCategorylist() {
    return this.http.get<any[]>(environment.apiBaseUrl + '/category');
  }

  getCategory(cid) {
    return this.http.get(environment.apiBaseUrl + '/category/' + cid);
  }

  getCountrylist() {
    return this.http.get(environment.apiBaseUrl + '/country');
  }

  getProductsbyCategory(cid){
    return this.http.get(environment.apiBaseUrl + '/products/bycid/'+cid);
  }
  
}
