import { Component, OnInit } from '@angular/core';
import { UserService } from '../_services/user.service';
import { Router } from "@angular/router";
import {NgSelectModule, NgOption} from '@ng-select/ng-select';
import { Location } from '@angular/common';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  userDetails: any;
  selectedlanguageName = 'English';
  
  countryList = [
    {id: 1, name: 'English', avatar: '../../assets/img/en.jpg'},
    {id: 2, name: 'Español', avatar: '../../assets/img/es.jpg'},
    {id: 3, name: 'Português', avatar: '../../assets/img/pt.jpg'}
  ];

  constructor(private userService: UserService, private router: Router, private location: Location,) { }

  ngOnInit() {
    this.getProfile();
  }

  getProfile(){
    this.userService.getUserProfile().subscribe(
      res => {
        console.log(res);
        this.userDetails = res;
      },
      err => { 
        console.log(err);
      }
    );
  }

  onLogout(){
    this.userService.deleteToken();
    this.router.navigate(['/login']);
  }

  goBack(): void {
    this.location.back();
  }
}
