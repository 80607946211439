import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { UserService } from '../../_services/user.service'
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css']
})
export class SignUpComponent implements OnInit {
  userForm: FormGroup;
  submitted = false;
  message: string;
  showSucessMessage: boolean;
  serverErrorMessages: string;
  showLoader: boolean;

  showWarningMessage: boolean;
  emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  constructor(
    private userService: UserService, 
    private formBuilder: FormBuilder,
    private location: Location,
    private router : Router,
    private route: ActivatedRoute,
    ) {
      this.showLoader = false;
     }

  ngOnInit() {
    this.userForm = this.formBuilder.group({
      firstname: ['', Validators.required],
      lastname: [''],
      username: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      isactive: [],
      country: ['', Validators.required],
      company: ['', Validators.required],
      type: ['', Validators.required],
      cardnumber: [''],
      password: ['', [Validators.required, Validators.minLength(6)]],
    });
  }

  get f() { return this.userForm.controls; }

  onSubmit() {
    this.submitted = true;
   
    const username = this.userForm.value['email'];
    this.userForm.controls['username'].setValue(username);
    this.userForm.controls['type'].setValue('user');
    if (this.userForm.valid) {
      this.showLoader = true;
      this.userForm.controls['isactive'].setValue(false);

      this.userService.postUser(this.userForm.value).subscribe(
        res => {
          this.message = res['message'];
          if (this.message === "User registration is successful.") {
            this.showSucessMessage = true;
            setTimeout(() => this.showSucessMessage = false, 4000);
            this.router.navigateByUrl('/login');
          } else {
            this.showWarningMessage = true;
            this.showLoader = false;
            setTimeout(() => this.showWarningMessage = false, 4000);
          }
        },
        err => {
          this.showLoader = false;
          if (err.status === 422) {
            this.serverErrorMessages = err.error.join('<br/>');
          }
          else
            this.serverErrorMessages = 'Something went wrong.Please contact admin.';
        }
      );
    }
  }

  // goBack(): void {
  //   this.location.back();
  // }

  resetForm() {
    this.userForm.reset();
  }

}
