import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { UserService } from '../../_services/user.service';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { from } from 'rxjs';
@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.css']
})
export class ResetComponent implements OnInit {
  resetToken: string;
  resetPassForm: FormGroup;
  submitted = false;
  showSucessMessage: boolean;
  serverErrorMessages: string;
  showWarningMessage:boolean;
  message: string;
  userData: any;
  showLoader: boolean;
  constructor(
    private route: ActivatedRoute, 
    private userService: UserService,
    private formBuilder: FormBuilder
    ) {
    this.route.params.forEach((urlParams) => {
      this.resetToken = urlParams['resettoken'];
    });
    this.showLoader = false;
   }

  ngOnInit() {
    console.log(this.resetToken);
    this.getToken();
    this.resetPassForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(6)]],
      email: [],
      firstname: [],
      resettoken: []
    });
  }

  get f() { return this.resetPassForm.controls; }

  getToken(){
    this.userService.getResetToken(this.resetToken).subscribe(
      res => {
          console.log(res);
          this.userData = res['user'];
      }
    );
  }

  submit(){
    this.submitted = true;
    const getinfo = this.userData;
    if (this.resetPassForm.valid) {
      this.showLoader = true;
      this.resetPassForm.controls['email'].setValue(getinfo['email']);
      this.resetPassForm.controls['firstname'].setValue(getinfo['firstname']);
      this.resetPassForm.controls['resettoken'].setValue(getinfo['resettoken']);
      this.userService.changePassword(this.resetPassForm.value).subscribe(
        res => {
              this.message = res['message'];
              if(this.message === "Password has been reset!"){
                this.showSucessMessage = true;
                setTimeout(() => this.showSucessMessage = false, 4000);
                this.showLoader = false;
              }else{
                this.showLoader = false;
                this.showWarningMessage = true;
                setTimeout(() => this.showWarningMessage = false, 4000);
              }
            },
            err => {
              this.showLoader = false;
              this.serverErrorMessages = err.error.message;
            }
      );
    }
  }
}
