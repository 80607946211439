import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { User } from '../_models/user.model';
import { LoginUser } from '../_models/login.model';
import { ForgotPassword } from '../_models/forgotpassword.model';
import { ResetPassword } from '../_models/resetpaassword.model';
import { from } from 'rxjs';
import 'rxjs/add/operator/map'
@Injectable({
  providedIn: 'root'
})
export class UserService {

  selectedUser: User = {
    firstname: '',
    lastname: '',
    username: '',
    email: '',
    country: '',
    company:'',
    isactive: false,
    password:'',
    type: '',
    cardnumber: '',
    resettoken: ''
  };

  loginUser: LoginUser = {
    email:'',
    password:''
  };

  forgotUserpassword: ForgotPassword = {
    email:''
  };

  resetPass: ResetPassword = {
    password:'',
    email: '',
    resettoken: ''
  }

  noAuthHeader = { headers: new HttpHeaders({ 'NoAuth': 'True' }) };

  constructor(private http: HttpClient) { }

  //HttpMethods

  postUser(user: User){
    return this.http.post(environment.apiBaseUrl+'/users/register', user,);
  }

  login(user: LoginUser) {
    return this.http.post(environment.apiBaseUrl + '/users/login', user);
  }

  getUserProfile() {
    return this.http.get(environment.apiBaseUrl + '/users/profile');
  }

  forgotpassword(forgotUserpassword: ForgotPassword) {
    return this.http.post(environment.apiBaseUrl + '/users/forgot', forgotUserpassword);
  }

  getResetToken(resetToken) {
    return this.http.get(environment.apiBaseUrl + '/users/resetpassword/'+resetToken);
  }

  changePassword(resetPass: ResetPassword){
    return this.http.put(environment.apiBaseUrl+'/users/savepassword', resetPass);
  }
  //Helper Methods

  setToken(token: string) {
    localStorage.setItem('token', token);
  }

  getToken() {
    return localStorage.getItem('token');
  }

  deleteToken() {
    localStorage.removeItem('token');
  }

  getUserPayload() {
    var token = this.getToken();
    if (token) {
      var userPayload = atob(token.split('.')[1]);
      return JSON.parse(userPayload);
    }
    else
      return null;
  }

  isLoggedIn() {
    var userPayload = this.getUserPayload();
    console.log(userPayload);
    if (userPayload)
      return userPayload.exp > Date.now() / 1000;
    else
      return false;
  }
}
