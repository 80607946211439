import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { UserService } from '../../../_services/user.service'
import { GoldService } from '../../../_services/gold.service'
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-goldregistercomp',
  templateUrl: './goldregistercomp.component.html',
  styleUrls: ['./goldregistercomp.component.css']
})
export class GoldregistercompComponent implements OnInit {

  userForm: FormGroup;
  submitted = false;
  message: string;
  showSucessMessage: boolean;
  serverErrorMessages: string;
  cardnumber: string;
  showLoader: boolean;
  showWarningMessage: boolean;
  emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  constructor(
    private userService: UserService, 
    private formBuilder: FormBuilder,
    private router : Router,
    private location: Location,
    private goldService: GoldService,
    private route: ActivatedRoute,
    ) {
      this.showLoader = false;
     }

  ngOnInit() {
    this.userForm = this.formBuilder.group({
      firstname: ['', Validators.required],
      lastname: [''],
      username: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      isactive: [],
      country: ['',Validators.required],
      company: ['', Validators.required],
      type: ['', Validators.required],
      cardnumber: ['', [Validators.required, Validators.minLength(8)]],
      password: ['', [Validators.required, Validators.minLength(6)]],
    });
  }

  get f() { return this.userForm.controls; }

  onSubmit() {
    this.submitted = true;
    const username = this.userForm.value['email'];
    this.userForm.controls['username'].setValue(username);
    this.userForm.controls['type'].setValue('gold');
    console.log(this.userForm.value);
    if (this.userForm.valid) {
      this.showLoader = true;
      this.userForm.controls['isactive'].setValue(true);
      this.cardnumber = this.userForm.value['cardnumber'];

      this.goldService.getCard(this.cardnumber).subscribe(
        res => {
          const status = res['success'];
          
          if(status === true){
              const data = res['data'];
              const cardStatus = data['assigned'];
              const cardId = data['_id'];
              if(cardStatus === false){
                this.userService.postUser(this.userForm.value).subscribe(
                  res => {
                    this.message = res['message'];
                    if (this.message === "User registration is successful.") {
                      const changeStaus = true;
                      this.goldService.updateCard(cardId, changeStaus).subscribe(
                        res => {
                          // console.log('Assign True');
                        }
                      )
                      this.showSucessMessage = true;
                      this.showLoader = false;
                      setTimeout(() => this.showSucessMessage = false, 4000);
                      this.router.navigateByUrl('/login');
                    } else {
                      this.showLoader = false;
                      this.showWarningMessage = true;
                      setTimeout(() => this.showWarningMessage = false, 4000);
                    }
                    // this.goBack();
                  },
                  err => {
                    this.showLoader = false;
                    if (err.status === 422) {
                      this.serverErrorMessages = err.error.join('<br/>');
                    }
                    else
                      this.serverErrorMessages = 'Something went wrong.Please contact admin.';
                  }
                );
              }else{
                this.showLoader = false;
                this.message = "Card Number alredy assigned";
                this.showWarningMessage = true;
                setTimeout(() => this.showWarningMessage = false, 4000);
              }
          }else{
            this.showLoader = false;
            this.message = "Card Number is Not Valid.";
            this.showWarningMessage = true;
            setTimeout(() => this.showWarningMessage = false, 4000);
          }
        }
      )
    }
  }

  goBack(): void {
    this.location.back();
  }
  
  resetForm() {
    this.userForm.reset();
  }

}
