import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})


export class ImageLibraryService {
  
  apiKey = "az38bw672E0"
  constructor(private http: HttpClient) { }

  httpheader = { headers: new HttpHeaders({ 'X-PS-Api-Key': 'az38bw672E0' })};
  params = [
    'X-PS-Api-Key=' + this.apiKey,
  ];
  paramsStr = this.params.join("&");
  getcollection() {
    httpOptions.headers.append('Access-Control-Allow-Origin', 'http://localhost:8080');
    httpOptions.headers.append('Access-Control-Allow-Methods', 'GET, POST, OPTIONS, PUT, PATCH, DELETE');
    httpOptions.headers.append('Access-Control-Allow-Headers', 'X-Requested-With,content-type');
    httpOptions.headers.append('Access-Control-Allow-Credentials', 'true');
    return this.http.get('https://creativetravel.photoshelter.com/psapi/v3/collection/root/children' + '?api_key=az38bw672E0');
  }

}
