import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cover-note',
  templateUrl: './cover-note.component.html',
  styleUrls: ['./cover-note.component.scss']
})
export class CoverNoteComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
