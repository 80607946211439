import { Component, OnInit } from '@angular/core';
import { LandingService } from '../../_services/landing.service';
import { Router} from "@angular/router";
import * as _ from 'lodash';
@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {
  catlist: any;
  showLoader: boolean;
  cid: any;

  constructor(private landingService: LandingService, private router: Router ) {
    this.showLoader = true;
  }

  ngOnInit() {
    this.showLoader = true;
    this.getlist();
  }

  getlist() {
    var catlist = [];
    this.landingService.getCategorylist().subscribe(
      res => {
        this.catlist = res;
        console.log(res);
        //short by order
        this.catlist.sort(function (a, b) {
          return a.order - b.order
        })
        this.showLoader = false;
      },
      err => {
        console.log(err);
      }
    );
  }


  listView(){
    this.router.navigate(['/user/list', {cid: this.cid}]);
  }

  gridView(){
    this.router.navigate(['/user/grid', {cid: this.cid}]);
  }

  indigenous(){
    this.router.navigate(['/user/experiences', {cid: this.cid}]);
  }

  festival(){
    this.router.navigate(['/user/festivals', {cid: this.cid}]);
  }

}
