import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { AppComponent } from './app.component';
// import { AppRoutingModule } from './app-routing.module';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HttpClientModule,HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgxGalleryModule } from 'ngx-gallery';
import { NgxPaginationModule} from 'ngx-pagination';
import { NgSelectModule } from '@ng-select/ng-select';
import { TruncateTextPipe } from './_pipes/truncate-text.pipe';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { DatePipe } from '@angular/common';
//conponents
import { UserComponent } from './user/user.component';
import { SignUpComponent } from './user/sign-up/sign-up.component';
import { SignInComponent } from './user/sign-in/sign-in.component';
import { LandingComponent } from './user/landing/landing.component';
import { ForgotComponent } from './user/forgot/forgot.component';
import { ResetComponent } from './user/reset/reset.component';
import { HeaderComponent } from './header/header.component';
import { HeaderLoginComponent } from './header-login/header-login.component';

import { appRoutes } from './routes';
import { AuthGuard } from './auth/auth.guard';
import { AuthInterceptor } from './auth/auth.interceptor';
import { UserService } from './_services/user.service';
import { GoldSignupComponent } from './user/gold-signup/gold-signup.component';
import { LoaderComponent } from './shared/loader/loader.component';
import { CoverNoteComponent } from './shared/cover-note/cover-note.component';
import { LinkComponent } from './user/link/link.component';
import { ItenaryComponent } from './user/itenary/itenary.component';
import { GridComponent } from './user/grid/grid.component';
import { AboutComponent } from './user/about/about.component';
import { HomeComponent } from './user/home/home.component';
import { LogincompComponent } from './shared/components/logincomp/logincomp.component';
import { RegistercompComponent } from './shared/components/registercomp/registercomp.component';
import { GoldregistercompComponent } from './shared/components/goldregistercomp/goldregistercomp.component';
import { GridinfoComponent } from './user/grid/gridinfo/gridinfo.component';
import { ExperiencesComponent } from './user/experiences/experiences.component';
import { FestivalsComponent } from './user/festivals/festivals.component';
import { ImageLibraryComponent } from './user/image-library/image-library.component';

@NgModule({
  declarations: [
    AppComponent,
    UserComponent,
    SignUpComponent,
    SignInComponent,
    LandingComponent,
    ForgotComponent,
    ResetComponent,
    HeaderComponent,
    HeaderLoginComponent,
    GoldSignupComponent,
    LoaderComponent,
    CoverNoteComponent,
    LinkComponent,
    TruncateTextPipe,
    ItenaryComponent,
    GridComponent,
    AboutComponent,
    HomeComponent,
    LogincompComponent,
    RegistercompComponent,
    GoldregistercompComponent,
    GridinfoComponent,
    ExperiencesComponent,
    FestivalsComponent,
    ImageLibraryComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    LazyLoadImageModule,
    BrowserModule,
    HttpClientModule,
    NgxGalleryModule,
    NgxPaginationModule,
    NgSelectModule,
    ReactiveFormsModule,
    RouterModule.forRoot(appRoutes),
  ],

  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true
  },AuthGuard, UserService, DatePipe, {provide: LocationStrategy, useClass: HashLocationStrategy}],
  
  bootstrap: [AppComponent]
})
export class AppModule { }
