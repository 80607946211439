import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Response, RequestOptions, Headers, Http, URLSearchParams} from '@angular/http';

export interface subProduct {
  cid: string;
  title: string;
  description: string;
  thumb: string;
  attachment?: string;
  attachmentview?: string;
  country: object;
  city?: string;
  _id?: string
}

@Injectable({
  providedIn: 'root'
})
export class SubCategoryService {

  constructor(private http: HttpClient) { }

  getsubCategorylist(cid) {
    return this.http.get<any[]>(environment.apiBaseUrl + '/subproducts/bycid/'+cid);
  }

  getProductsbyCategory(cid){
    return this.http.get<any[]>(environment.apiBaseUrl + '/products/'+cid);
  }

  getCountrylist() {
    return this.http.get(environment.apiBaseUrl + '/country');
  }
}
