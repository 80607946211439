import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';

export interface City {
  name: string;
  cid: string;
  id?: string
}

@Injectable({
  providedIn: 'root'
})
export class CityService {

  constructor(private http: HttpClient) { }

  getcityList(cname){
    return this.http.get(environment.apiBaseUrl + '/city/bycountry/'+cname);
  }

  getcityAll(){
    return this.http.get(environment.apiBaseUrl + '/city');
  }

}
