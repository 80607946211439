import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { LandingService } from '../../_services/landing.service';
import { DomSanitizer } from '@angular/platform-browser';
import { DatePipe } from '@angular/common'

@Component({
  selector: 'app-festivals',
  templateUrl: './festivals.component.html',
  styleUrls: ['./festivals.component.scss']
})
export class FestivalsComponent implements OnInit {

  cid:any;
  catItem: any;
  countrylist: any;
  selectedCountry: any;
  selectedMonth:any;
  fileUrl;
  taglist;
  selCountry;
  selmonth;
  showLoader: boolean;
  productsCidData: any;
  selectedCountryName;
  selectedMonthName;

  

  public months = [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ];;

  productFilter: Array<any>;
  productList: Array<any>;

  constructor( private route: ActivatedRoute, private router: Router, public datepipe: DatePipe, private sanitizer: DomSanitizer, private landingService: LandingService,) { 
    this.route.params.forEach((urlParams) => {
      this.cid = urlParams['cid'];
    });
    this.showLoader = true;
  }

  ngOnInit() {
    this.getCategory();
    this.getCountry();
    this.getProductlist();
    const data = '';
    const blob = new Blob([data], { type: 'application/octet-stream' });
    this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));
  }

  getCategory() {
    this.landingService.getCategory(this.cid).subscribe(
      res => {
        this.catItem = res;
      },
      err => {
        console.log(err);
      }
    );
  }

  getCountry(){
    this.landingService.getCountrylist().subscribe(
      res => {
        this.countrylist = res;
      },
      err => { 
        console.log(err);
      }
    );
  }

  listClick(event, newValue) {
    this.showLoader = true;
    this.selectedCountry = newValue;  // don't forget to update the model here
    this.selectedCountryName = this.selectedCountry['name']
    this.getProductlist(this.selectedCountryName, this.selectedMonthName);
  }

  monthClick(event, newValue) {
    this.showLoader = true;
    this.selectedMonth = newValue;  // don't forget to update the model here
    this.selectedMonthName = this.selectedMonth;
   
    this.getProductlist(this.selectedCountryName, this.selectedMonthName);
  }

  getProductlist(selCountry?, selmonth?){
    this.productFilter = [];
    this.landingService.getProductsbyCategory(this.cid).subscribe(
      res => {
        this.productsCidData = res;
        this.productsCidData.forEach(el =>{
          let _title = el['title'];
          let _description = el['description'];
          let _country= el['country'];
          let _months= el['months'];
          let _places= el['places'];
          let _duration = el['duration'];
          let _edate = el['eventDate']
          let _eventDate = this.datepipe.transform(_edate, 'd MMMM y');
          let _eventMonth = this.datepipe.transform(_edate, 'MMMM');
          let _thumb = el['thumb'];
          let _attachment= el['attachment'];
          let _attachmentview= el['attachmentview'];
          let _url= el['url'];
          this.productFilter.push({'title':_title, 'description':_description, 'country': _country, 'duration': _duration, 'eventDate': _eventDate, 'eventMonth': _eventMonth, 'thumb': _thumb, 'attachment': _attachment, 'attachmentview':_attachmentview, 'places': _places, 'url': _url});
        })

          this.productList = [];
          this.productFilter.forEach(data =>{

            if((selCountry === undefined || selCountry === "All") && (selmonth === undefined)){
              this.productList.push(data);
            }else if((selCountry === undefined || selCountry === "All") && (selmonth !== undefined)){
              if ((data.eventMonth !==null && data.eventMonth.indexOf(selmonth) > -1)) {
                  this.productList.push(data);
              }
            }else if((selCountry !== undefined || selCountry !== "All") && (selmonth === undefined)){
              if ((data.country !==null && data.country.indexOf(selCountry) > -1)) {
                  this.productList.push(data);
              }
            }else if((selCountry !== undefined || selCountry !== "All") && (selmonth !== undefined)){
              if ((data.country !==null && data.country.indexOf(selCountry) > -1) && (data.eventMonth !==null && data.eventMonth.indexOf(selmonth) > -1)) {
                  this.productList.push(data);
              }
            }else{
              this.productList.push(data);
            }
           
          })
        
        this.showLoader = false;
      },
      err => { 
        console.log(err);
      }
    );
  }
  

}
