import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { ImageLibraryService } from '../../_services/image-library.service';
@Component({
  selector: 'app-image-library',
  templateUrl: './image-library.component.html',
  styleUrls: ['./image-library.component.css']
})
export class ImageLibraryComponent implements OnInit {

  constructor(private route: ActivatedRoute, private router: Router, private imageLibraryService: ImageLibraryService,) { }

  ngOnInit() {
    this.getImage();
  }


  getImage(){
    this.imageLibraryService.getcollection().subscribe(
      res => {
        console.log(res);
      },
      err => { 
        console.log(err);
      }
    );
  }
}
