import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { LandingService } from '../../_services/landing.service';
import { TagsService } from '../../_services/tags.service';
import { CityService } from '../../_services/city.service';
import { DomSanitizer } from '@angular/platform-browser';
import { DatePipe } from '@angular/common'
@Component({
  selector: 'app-experiences',
  templateUrl: './experiences.component.html',
  styleUrls: ['./experiences.component.scss']
})
export class ExperiencesComponent implements OnInit {

  cid:any;
  catItem: any;
  countrylist: any;
  citylist: any;
  selectedCountry: any;
  selectedCity: any;
  selectedTag:any;
  selectedTagName:any;
  fileUrl;
  taglist;
  selCountry;
  selTag;
  selCity;
  showLoader: boolean;
  productsCidData: any;
  
  productFilter: Array<any>;
  productList: Array<any>;

  constructor( private route: ActivatedRoute, private cityService: CityService, private router: Router, public datepipe: DatePipe,  private tagsService:TagsService, private sanitizer: DomSanitizer, private landingService: LandingService,) { 
    this.route.params.forEach((urlParams) => {
      this.cid = urlParams['cid'];
    });
    this.showLoader = true;
  }

  ngOnInit() {
    this.getCategory();
    this.getCountry();
    this.getProductlist();
    this.getTags();
    const data = '';
    const blob = new Blob([data], { type: 'application/octet-stream' });
    this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));

  }

  getCategory() {
    this.landingService.getCategory(this.cid).subscribe(
      res => {
        this.catItem = res;
      },
      err => {
        console.log(err);
      }
    );
  }

  getTags(){
    this.tagsService.getTaglist().subscribe(
      res => {
        this.taglist = res;
      }
    )
  }

  getCountry(){
    this.landingService.getCountrylist().subscribe(
      res => {
        this.countrylist = res;
      },
      err => { 
        console.log(err);
      }
    );
  }

  countryonChange($event){
    this.getCitylist(this.selectedCountry);
    if(this.selectedCountry === null){
        this.selectedCity = "Select City"
    }

    this.selCountry = this.selectedCountry;
    this.getProductlist(this.selectedCountry, this.selectedTagName, this.selectedCity);
  }

  cityonChange($event){
    this.selCity = this.selectedCity;
    this.getProductlist(this.selectedCountry, this.selectedTagName, this.selectedCity);
  }

  getCitylist(cname?){
    this.cityService.getcityList(cname).subscribe(
      res => {
        this.citylist = res;
      },
      err => { 
        console.log(err);
      }
    );
  }

  tagClick(event, newValue) {
    this.showLoader = true;
    this.selectedTag = newValue;  // don't forget to update the model here
    this.selectedTagName = this.selectedTag['name']
    this.getProductlist(this.selectedCountry, this.selectedTagName, this.selectedCity);
  }

  getProductlist(selCountry?, selTag?, selCity?){
    this.productFilter = [];
    this.landingService.getProductsbyCategory(this.cid).subscribe(
      res => {
        this.productsCidData = res;
        this.productsCidData.forEach(el =>{
          let _title = el['title'];
          let _description = el['description'];
          let _country= el['country'];
          let _city= el['city'];
          let _tags= el['tags'];
          let _places= el['places'];
          let _duration = el['duration'];
          let _edate = el['eventDate']
          let _eventDate = this.datepipe.transform(_edate, 'd MMMM y');
          let _thumb = el['thumb'];
          let _attachment= el['attachment'];
          let _attachmentview= el['attachmentview'];
          let _url= el['url'];
          this.productFilter.push({'title':_title, 'description':_description, 'tags': _tags, 'country': _country, 'city': _city, 'duration': _duration, 'eventDate': _eventDate, 'thumb': _thumb, 'attachment': _attachment, 'attachmentview':_attachmentview, 'places': _places, 'url': _url});
        })

          this.productList = [];
          this.productFilter.forEach(data =>{
           
            if((selCountry === undefined || selCountry === null) && (selTag === undefined || selTag === null) && (selCity === undefined || selCity === null || selCity === "Select City")){
              this.productList.push(data);
            }else if((selCountry !== undefined || selCountry !== null) && (selTag === undefined || selTag === null) && (selCity === undefined || selCity === null || selCity === "Select City") ){
                if (data.country.indexOf(selCountry) > -1) {
                    this.productList.push(data);
                }
            }else if((selCountry !== undefined || selCountry !== null) && (selTag === undefined || selTag === null) && (selCity !== undefined || selCity !== null || selCity !== "Select City") ){
              if ((data.country.indexOf(selCountry) > -1) && (data.city !==null && data.city.indexOf(selCity) > -1)) {
                  this.productList.push(data);
              }
            }else if((selCountry === undefined || selCountry === null) && (selTag !== undefined || selTag !== null) && (selCity === undefined || selCity === null || selCity === "Select City") ){
              if (data.tags !==null && data.tags.indexOf(selTag) > -1) {
                  this.productList.push(data);
              }
            }else if((selCountry !== undefined || selCountry !== null) && (selTag !== undefined || selTag !== null) && (selCity === undefined || selCity === null || selCity === "Select City") ){
              if ((data.tags !==null && data.tags.indexOf(selTag) > -1) && (data.country.indexOf(selCountry) > -1)) {
                  this.productList.push(data);
              }
            }else if((selCountry !== undefined || selCountry !== null) && (selTag !== undefined || selTag !== null) && (selCity !== undefined || selCity !== null || selCity !== "Select City") ){
              if ((data.country.indexOf(selCountry) > -1) && (data.city !==null && data.city.indexOf(selCity) > -1) && (data.tags !==null && data.tags.indexOf(selTag) > -1)) {
                  this.productList.push(data);
              }
            }else if((selCountry === undefined || selCountry === null) && (selTag !== undefined || selTag !== null) && (selCity === undefined || selCity === null || selCity === "Select City") ){
              if (data.tags !==null && data.tags.indexOf(selTag) > -1) {
                  this.productList.push(data);
              }
            }
            else{
              this.productList.push(data);
            }
          })
        
        this.showLoader = false;
      },
      err => { 
        console.log(err);
      }
    );
  }

}
