import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { UserService } from '../../../_services/user.service';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-logincomp',
  templateUrl: './logincomp.component.html',
  styleUrls: ['./logincomp.component.css']
})
export class LogincompComponent implements OnInit {
  loginForm: FormGroup;
  submitted = false;
  showSucessMessage: boolean;
  serverErrorMessages: string;
  showLoader: boolean;
  showWarningMessage:boolean;
  message: string;
  emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  constructor(
    private userService:UserService,
    private router : Router,
    private formBuilder: FormBuilder,
    ) {
      this.showLoader = false;
     }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    });
    if(this.userService.isLoggedIn())
    this.router.navigateByUrl('/user/landing');
  }

  get f() { return this.loginForm.controls; }


  submit(){
    this.submitted = true;
  
    if(this.loginForm.valid){
      this.showLoader = true;
      this.userService.login(this.loginForm.value).subscribe(
        res => {
          this.userService.setToken(res['token']);
          this.router.navigateByUrl('/user/landing');
          
          this.message = res['message'];
          if(this.message === "User registration is successful."){
            this.showSucessMessage = true;
            setTimeout(() => this.showSucessMessage = false, 4000);
          }else{
            this.showLoader = false;
            this.showWarningMessage = true;
            setTimeout(() => this.showWarningMessage = false, 4000);
          }
        },
        err => {
          this.showLoader = false;
          this.serverErrorMessages = err.error.message;
        }
      );
    }
    
  }

}
