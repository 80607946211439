import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { SubCategoryService } from '../../../_services/sub-category.service';
@Component({
  selector: 'app-gridinfo',
  templateUrl: './gridinfo.component.html',
  styleUrls: ['./gridinfo.component.scss']
})
export class GridinfoComponent implements OnInit {

  cid;
  showLoader: boolean;
  catItem;
  countrylist: any;
  selectedCountry: any;
  productsCidData: any;
  productFilter: Array<any>;
  productList: Array<any>;

  constructor( private route: ActivatedRoute, private router: Router, private subCategoryService: SubCategoryService) {
    this.route.params.forEach((urlParams) => {
      this.cid = urlParams['cid'];
    });
    this.showLoader = true;
   }

  ngOnInit() {
    this.getCat()
    this.getpostlist();
    this.getProductlist();
    this.getCountry();
  }


  getpostlist() {
    this.subCategoryService.getsubCategorylist(this.cid).subscribe(
      res => {
        console.log(res);
      }
    )
  }

  getCountry(){
    this.subCategoryService.getCountrylist().subscribe(
      res => {
        this.countrylist = res;
        //console.log(res);
      },
      err => { 
        console.log(err);
      }
    );
  }

  listClick(event, newValue) {
    this.selectedCountry = newValue;  // don't forget to update the model here
    if(this.selectedCountry === undefined){
      this.selectedCountry = "All";
    }else{
      this.selectedCountry = newValue;
    }
    this.getProductlist(this.selectedCountry);
  }


  getProductlist(selCountry?){
    this.productFilter = [];
    this.subCategoryService.getsubCategorylist(this.cid).subscribe(
      res => {
        this.productsCidData = res;
        console.log(this.productsCidData);
        this.productsCidData.forEach(el =>{
          let _id = el['_id'];
          let _title = el['title'];
          let _description = el['description'];
          let _country= el['country'];
          let _thumb = el['thumb'];
          let _attachment= el['attachment'];
          let _attachmentview= el['attachmentview'];
          let _url= el['url'];

          this.productFilter.push({'id':_id,'title':_title, 'description':_description, 'country': _country, 'thumb': _thumb, 'attachment': _attachment, 'attachmentview': _attachmentview, 'url': _url});
     
        })

        console.log(this.productFilter);
        console.log(selCountry);
        this.productList = [];
        this.productFilter.forEach(data =>{
          if((selCountry === undefined) || (selCountry['name'] === "All")){
            this.productList.push(data);
          }else{
            if (data.country.indexOf(selCountry['name']) > -1) {
              this.productList.push(data);
            }
          }
        })
      
      this.showLoader = false;
      },
      err => { 
        console.log(err);
      }
    );
  }

  getCat() {
    this.subCategoryService.getProductsbyCategory(this.cid).subscribe(
      res => {
        this.catItem = res;
        this.showLoader = false;
      }
    )
  }
}
