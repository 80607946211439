import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Card } from '../_models/card.model';

@Injectable({
  providedIn: 'root'
})
export class GoldService {

  selectedCard: Card = {
    id: '',
    cardnumber: '',
    assigned: false,
  };

  constructor(private http: HttpClient) { }


  getCard(cardnumber){
    return this.http.get(environment.apiBaseUrl + '/card/bynum/'+cardnumber);
  }

  //update card status 
  updateCard(cardId, assignStatus) {
    console.log(assignStatus);
    let body = JSON.stringify([{"propName": "assigned", "value": assignStatus}]);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});

    return this.http.patch(environment.apiBaseUrl+ '/card/'+cardId, body,{
        headers: headers,
        observe: 'response'
    }
  )}
}
